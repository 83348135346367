import { localStorageService } from '@/services/local-storage-service';
import { useState } from 'react';

type State<T> = T[keyof T];

const getPersistedState = <T extends Record<string, string>>(
  key: string,
  states: T,
  defaultState: State<T> | undefined,
): State<T> | undefined => {
  try {
    const persisted = localStorageService.getItem(key) || '';
    // check if states contain persisted value
    return Object.values(states).includes(persisted)
      ? states[
          // find key and set persisted value
          Object.entries(states).find(
            (entry) => entry[1] === persisted,
          )?.[0] as keyof T
        ]
      : defaultState;
  } catch {
    return defaultState;
  }
};

const persistState = <T extends Record<string, string>>(
  key: string,
  state: State<T>,
): void => {
  localStorageService.setItem(key, state);
};

const usePersistState = <T extends Record<string, string>>({
  key,
  states,
  defaultState,
}: {
  key: string;
  states: T;
  defaultState?: State<T> | undefined;
}) => {
  const [initialState] = useState(getPersistedState(key, states, defaultState));
  const [state, setState] = useState(initialState);

  return {
    state,
    initialState,
    setState: (value: State<T>) => {
      setState(value);
      persistState(key, value);
    },
  };
};

export { getPersistedState, persistState, usePersistState };
